import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  // @ts-ignore
  scrollBehavior: () => {
    return ({ x: 0, y: 0, top: 0 })
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/main/Start.vue'),
      children: [
        {
          path: '/old',
          component: () => import('./views/landing/LandingOld.vue'),
        },
        {
          path: '/',
          component: () => import('./views/landing/Landing.vue'),
        },
        {
          path: '/about',
          component: () => import('./views/landing/LandingAbout.vue'),
        },
        {
          path: '/dexnode',
          component: () => import('./views/landing/LandingDexNode.vue'),
        },
        {
          path: '/login',
          component: () => import('./views/Login.vue'),
        },
        // {
        //   path: '/map',
        //   component: () => import('./views/Map.vue'),
        // },

        {
          path: '/wallet-confirm',
          component: () => import('./views/WalletConfirm.vue'),
        },
        {
          path: '/wallet-remove',
          component: () => import('./views/WalletConfirm.vue'),
        },

        {
          path: '/withdraw-confirm',
          component: () => import('./views/WithdrawConfirm.vue'),
        },

        {
          path: '/',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import('./views/main/Dashboard.vue'),
            },
            {
              path: 'finance',
              component: () => import('./views/main/Finance.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
